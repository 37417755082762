:root {
    /*
        We create gradient variables for background radial gradients with the pattern --bg-{SECTION}-gradient.
        Here are the color codes for each section:
        section:general
        start:#862596, end:#080063
        section:warehouse
        start:#A49720, end:#F4FF72
        section:sales
        start:#20471A, end:#003463
        section:marketstudy
        start:#810875, end:#140063
        section:humanresources
        start:#dc8400, end:#472a1a
        section:research
        start:#281a47, end:#003463
        section:assembly
        start:#004A8F, end:#40A1A7
        section:events
        start:#642A34, end:#FD395D
    */
    --bg-general-gradient: radial-gradient(
        ellipse at center,
        #862596 0%,
        #080063 100%
    );
    --bg-warehouse-gradient: radial-gradient(
        ellipse at center,
        #a49720 0%,
        #f4ff72 100%
    );
    --bg-sales-gradient: radial-gradient(
        ellipse at center,
        #20471a 0%,
        #003463 100%
    );
    --bg-marketstudy-gradient: radial-gradient(
        ellipse at center,
        #810875 0%,
        #140063 100%
    );
    --bg-humanresources-gradient: radial-gradient(
        ellipse at center,
        #dc8400 0%,
        #472a1a 100%
    );
    --bg-research-gradient: radial-gradient(
        ellipse at center,
        #281a47 0%,
        #003463 100%
    );
    --bg-assembly-gradient: radial-gradient(
        ellipse at center,
        #004a8f 0%,
        #40a1a7 100%
    );
    --bg-events-gradient: radial-gradient(
        ellipse at center,
        #642a34 0%,
        #fd395d 100%
    );

    /*
        Now we create the gradiant variables for the circles to be shown over the background (--bg-{SECTION}-circle).
        Gradients are linear from top (start) to bottom (end):
        section:general
        start:#D4FFED, end:#2300AD
        section:warehouse
        start:#A49720, end:#F4FF72
        section:sales
        start:#67D70F, end:#00ADAD
        section:marketstudy
        start:#8900B9, end:#FF00E5
        section:humanresources
        start:#FA620D, end:#FFEC87
        section:research
        start:#6FE5FF, end:#2300AD
        section:assembly
        start:#6FF6FF, end:#00AD31
        section:events
        start:#FA0D0D, end:#FF5592
    */
    --bg-general-circle: linear-gradient(to bottom, #d4ffed 0%, #2300ad 100%);
    --bg-warehouse-circle: linear-gradient(to bottom, #a49720 0%, #f4ff72 100%);
    --bg-sales-circle: linear-gradient(to bottom, #67d70f 0%, #00adad 100%);
    --bg-marketstudy-circle: linear-gradient(
        to bottom,
        #8900b9 0%,
        #ff00e5 100%
    );
    --bg-humanresources-circle: linear-gradient(
        to bottom,
        #fa620d 0%,
        #ffec87 100%
    );
    --bg-research-circle: linear-gradient(to bottom, #6fe5ff 0%, #2300ad 100%);
    --bg-assembly-circle: linear-gradient(to bottom, #6ff6ff 0%, #00ad31 100%);
    --bg-events-circle: linear-gradient(to bottom, #fa0d0d 0%, #ff5592 100%);
}
