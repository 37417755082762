body {
    font: var(--font-current);
}
#wrapper {
    position: relative;
    max-width: 960px;
    min-height: calc(100vh - 60px);
    margin: 0 auto;
    font-weight: var(--font-weight-regular);
    color: var(--font-current-color);
    line-height: 1.25em;

    .color-warehouse {
        color: var(--color-warehouse-500);
    }

    .color-assembly {
        color: var(--color-assembly-500);
    }

    .color-research {
        color: var(--color-primary-500);
    }

    .color-hr {
        color: var(--color-humanresources-500);
    }

    .color-sales {
        color: var(--color-sales-300);
    }

    .color-events {
        color: var(--color-secondary-500);
    }

    .color-bank {
        color: var(--color-sales-600);
    }

    .color-positive {
        color: var(--color-sales-300);
    }

    .color-negative {
        color: var(--color-secondary-400);
    }

    .outer {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        border-radius: 4.68em;
        overflow: hidden;

        &::after {
            content: "";
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            position: absolute;
            z-index: -3;
            border-radius: 4.68em;
            border: 1em solid var(--wrapper-border-color);
            overflow: hidden;
        }

        &::before {
            content: "";
            display: block;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -4;
            position: absolute;
            background: var(--wrapper-bg-color);
        }

        .part {
            position: absolute;
            z-index: -2;
            display: grid;
            justify-items: center;
            align-items: center;
            grid-template-areas: "part";

            &.part1 {
                top: 0;
                width: 100%;
            }

            &.part2 {
                top: 0;
                bottom: 0;
                right: 0;
                width: 1.75em;
            }

            &.part3 {
                bottom: 0;
                width: 100%;
            }

            &.part4 {
                top: 0;
                bottom: 0;
                left: 0;
                width: 1.75em;
            }

            &::before {
                content: "";
                display: inline-block;
                position: relative;
                z-index: -1;
                background-color: var(--wrapper-part-behind-bg-color);
                grid-area: part;
            }

            &::after {
                content: "";
                display: inline-block;
                position: relative;
                z-index: 0;
                background-color: var(--wrapper-part-bg-color);
                grid-area: part;
            }

            &.part1::before,
            &.part3::before {
                width: 25em;
                height: 1em;
                clip-path: polygon(50% 100%, 0 0, 100% 0);
                align-self: start;
            }

            &.part1::after,
            &.part3::after {
                width: 10em;
                height: 1.75em;
                clip-path: polygon(15% 100%, 85% 100%, 100% 0, 0 0);
            }

            &.part2::before,
            &.part4::before {
                height: 25em;
                width: 1em;
                clip-path: polygon(0 50%, 100% 0, 100% 100%);
                align-self: center;
                justify-self: end;
            }

            &.part2::after,
            &.part4::after {
                width: 1.75em;
                height: 10em;
                clip-path: polygon(0 15%, 100% 0, 100% 100%, 0 85%);
            }

            &.part3::before {
                transform: rotate(180deg);
                align-self: end;
            }

            &.part3::after {
                transform: rotate(180deg);
            }

            &.part4::before {
                transform: rotate(180deg);
                justify-self: start;
            }

            &.part4::after {
                transform: rotate(180deg);
            }
        }
    }

    .content {
        padding: 5%;
        position: relative;
        z-index: 1;

        h1,
        h2 {
            font-size: 1.33em;
            margin-bottom: 0.5em;
        }

        p {
            margin-top: 0;
        }

        header {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1em;
            padding-bottom: 20px;

            .common,
            .menu {
                display: grid;
                grid-template-columns: 1fr;
                grid-template-rows: auto;
                justify-items: start;
            }

            .logo img {
                width: 100%;
                height: auto;
            }

            /*
                From ul/li, we remove the bullet and the padding
            */
            .menu {
                padding-top: 20px;
                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-rows: auto;
                    grid-gap: 3px;
                }

                li {
                    display: inline-block;

                    a {
                        transition: color 0.2s ease-in-out;
                    }

                    &.language {
                        margin-bottom: 15px;
                        a {
                            color: var(--color-gray-50);
                        }
                    }

                    &.how-to-play a {
                        color: var(--color-primary-500);
                    }

                    &.general a {
                        color: var(--color-marketstudy-500);
                    }

                    &.warehouse a {
                        color: var(--color-warehouse-500);
                    }

                    &.sales a {
                        color: var(--color-sales-300);
                    }

                    &.human-resources a {
                        color: var(--color-humanresources-500);
                    }

                    &.research-development a {
                        color: var(--color-primary-500);
                    }

                    &.assembly a {
                        color: var(--color-assembly-500);
                    }

                    &.bank a {
                        color: var(--color-sales-600);
                    }

                    &.events a {
                        color: var(--color-secondary-400);
                    }

                    a:hover {
                        color: var(--color-gray-50);
                    }

                    &.active a {
                        color: var(--color-gray-500);
                        cursor: default;
                        text-decoration: none;
                    }
                }
            }
        }

        section {
            padding: 10px 0 20px;
            border-top: 1px solid var(--color-primary-500);

            ul li {
                padding-bottom: 0.5em;
            }

            ul > li > ul {
                padding-top: 0.66em;
            }

            h2 {
                padding-bottom: 0.5em;
            }
        }

        .screenshot {
            max-width: 100%;
            padding: 10px;
            img {
                border-radius: 20px;
                width: 100%;
                height: auto;
            }
        }

        .grid-left-right {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1em;
            grid-template-areas: "left right";

            .left {
                grid-area: left;
            }

            .right {
                grid-area: right;
            }
        }
    }
}
/*
    We need a media query for mobile screens and less (768px)
    where the general font-size is reduced
*/
@media only screen and (max-width: 768px) {
    body {
        font: var(--font-mobile);

        #wrapper .content {
            padding: 8%;

            .grid-left-right {
                grid-template-columns: 1fr;
                grid-template-areas: "left" "right";
            }
        }
    }
}
