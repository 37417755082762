:root {
    --bg-current: radial-gradient(ellipse at center, #fff 0%, #fff 100%);
    --bg-circle-current: radial-gradient(to bottom, #fff 0%, #fff 100%);

    --wrapper-bg-color: rgba(0, 28, 36, 0.75);
    --wrapper-border-color: var(--color-gray-600);
    --wrapper-part-bg-color: var(--color-gray-400);
    --wrapper-part-behind-bg-color: var(--color-gray-300);

    --font-current: 16px "Orbitron", sans-serif;
    --font-mobile: 12px "Orbitron", sans-serif;
    --font-current-color: var(--color-gray-50);
    --font-weight-regular: 400;
    --font-weight-title: 700;
}
