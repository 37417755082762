body.events {
    --bg-current: var(--bg-events-gradient);
    --bg-circle-current: var(--bg-events-circle);

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: var(--color-secondary-500);
    }
}
