body.warehouse {
    --bg-current: var(--bg-warehouse-gradient);
    --bg-circle-current: var(--bg-warehouse-circle);

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: var(--color-warehouse-500);
    }
}
