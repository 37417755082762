body.how-to-play {
    --bg-current: var(--bg-marketstudy-gradient);
    --bg-circle-current: var(--bg-marketstudy-circle);

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: var(--color-primary-500);
    }
}
