:root {
    /*
        We create the color variables with the pattern --color-{TYPE}-{WEIGHT}.
        The first weight is 50, the next is 100 and from there, every other weight is by a step of 100 until 900.
        Here are the color codes for each type and weight:
        type:primary
        #e6faff, #b0efff, #8ae7ff, #54dbff, #33d5ff, #00cadd, #00b8e8, #008fb5, #006f8c, #00556b
        type:secondary
        #fbe6e6, #f2b0b0, #ec8a8a, #e35454, #dd3333, #d50000, #c20000, #970000, #750000, #590000
        type:warehouse
        #fffceb, #fff6c2, #fff2a5, #ffec7c, #ffe962, #ffe33b, #e8cf36, #b5a12a, #8c7d20, #6b5f19
        type:sales
        #effbe6, #cdf2b0, #b5ec8a, #93e354, #7edd33, #5ed500, #56c200, #439700, #347500, #265900
        type:assembly
        #e6f8f4, #b0eadd, #8ae0cc, #55d2b5, #34c9a7, #01bc91, #01ab84, #018567, #016750, #004f3d
        type:marketstudy
        #f7ebfa, #e7c1f0, #dba3e9, #ca7adf, #c060d9, #b038cf, #a033bc, #7d2893, #611f72, #4a1857
        type:humanresources
        #fff3e6, #ffdbb2, #ffc98c, #feb158, #fea138, #fe8a06, #e77e05, #b46204, #8c4f03, #6b3a03
    */

    --color-primary-50: #e6faff;
    --color-primary-100: #b0efff;
    --color-primary-200: #8ae7ff;
    --color-primary-300: #54dbff;
    --color-primary-400: #33d5ff;
    --color-primary-500: #00cadd;
    --color-primary-600: #00b8e8;
    --color-primary-700: #008fb5;
    --color-primary-800: #006f8c;
    --color-primary-900: #00556b;

    --color-secondary-50: #fbe6e6;
    --color-secondary-100: #f2b0b0;
    --color-secondary-200: #ec8a8a;
    --color-secondary-300: #e35454;
    --color-secondary-400: #dd3333;
    --color-secondary-500: #d50000;
    --color-secondary-600: #c20000;
    --color-secondary-700: #970000;
    --color-secondary-800: #750000;
    --color-secondary-900: #590000;

    --color-warehouse-50: #fffceb;
    --color-warehouse-100: #fff6c2;
    --color-warehouse-200: #fff2a5;
    --color-warehouse-300: #ffec7c;
    --color-warehouse-400: #ffe962;
    --color-warehouse-500: #ffe33b;
    --color-warehouse-600: #e8cf36;
    --color-warehouse-700: #b5a12a;
    --color-warehouse-800: #8c7d20;
    --color-warehouse-900: #6b5f19;

    --color-sales-50: #effbe6;
    --color-sales-100: #cdf2b0;
    --color-sales-200: #b5ec8a;
    --color-sales-300: #93e354;
    --color-sales-400: #7edd33;
    --color-sales-500: #5ed500;
    --color-sales-600: #56c200;
    --color-sales-700: #439700;
    --color-sales-800: #347500;
    --color-sales-900: #265900;

    --color-assembly-50: #e6f8f4;
    --color-assembly-100: #b0eadd;
    --color-assembly-200: #8ae0cc;
    --color-assembly-300: #55d2b5;
    --color-assembly-400: #34c9a7;
    --color-assembly-500: #01bc91;
    --color-assembly-600: #01ab84;
    --color-assembly-700: #018567;
    --color-assembly-800: #016750;
    --color-assembly-900: #004f3d;

    --color-marketstudy-50: #f7ebfa;
    --color-marketstudy-100: #e7c1f0;
    --color-marketstudy-200: #dba3e9;
    --color-marketstudy-300: #ca7adf;
    --color-marketstudy-400: #c060d9;
    --color-marketstudy-500: #b038cf;
    --color-marketstudy-600: #a033bc;
    --color-marketstudy-700: #7d2893;
    --color-marketstudy-800: #611f72;
    --color-marketstudy-900: #4a1857;

    --color-humanresources-50: #fff3e6;
    --color-humanresources-100: #ffdbb2;
    --color-humanresources-200: #ffc98c;
    --color-humanresources-300: #feb158;
    --color-humanresources-400: #fea138;
    --color-humanresources-500: #fe8a06;
    --color-humanresources-600: #e77e05;
    --color-humanresources-700: #b46204;
    --color-humanresources-800: #8c4f03;
    --color-humanresources-900: #6b3a03;

    /*
        Now tints of grays with the pattern --color-gray-{WEIGHT}
        #f0f0f0, #e0e0e0, #c2c2c2, #a3a3a3, #858585, #666666, #4d4d4d, #333333, #1a1a1a #0a0a0a
    */
    --color-gray-50: #f0f0f0;
    --color-gray-100: #e0e0e0;
    --color-gray-200: #c2c2c2;
    --color-gray-300: #a3a3a3;
    --color-gray-400: #858585;
    --color-gray-500: #666666;
    --color-gray-600: #4d4d4d;
    --color-gray-700: #333333;
    --color-gray-800: #1a1a1a;
    --color-gray-900: #0a0a0a;

    /*
        Pure white and black
    */
    --color-white: #ffffff;
    --color-black: #000000;

    /*
        Feedback colors. With the pattern --color-{TYPE}-{WEIGHT}
        type:success
        100:#CDF2B0, 500:#5ed500, 900:#275900
        type:error
        100:#F2B0B0, 500:#D50000, 900:#590000
        type:warning
        100:#FFDBB2, 500:#FE8A06, 900:#6B3A03
    */
    --color-success-100: #cdf2b0;
    --color-success-500: #5ed500;
    --color-success-900: #275900;

    --color-error-100: #f2b0b0;
    --color-error-500: #d50000;
    --color-error-900: #590000;

    --color-warning-100: #ffdbb2;
    --color-warning-500: #fe8a06;
    --color-warning-900: #6b3a03;
}
