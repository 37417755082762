body.human-resources {
    --bg-current: var(--bg-humanresources-gradient);
    --bg-circle-current: var(--bg-humanresources-circle);

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: var(--color-humanresources-500);
    }
}
