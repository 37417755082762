body.bank {
    --bg-current: var(--bg-sales-gradient);
    --bg-circle-current: var(--bg-sales-circle);

    h1,
    h2,
    h3,
    h4,
    h5 {
        color: var(--color-sales-600);
    }
}
