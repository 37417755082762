$circlesize: 90vh;
body {
    background: var(--bg-current);

    .bg {
        .circle {
            background: var(--bg-circle-current);
            display: block;
            position: fixed;
            width: $circlesize;
            height: $circlesize;
            border-radius: 50%;
            z-index: -999;
            filter: blur($circlesize / 7);
        }

        .circle1 {
            top: $circlesize / -3;
            left: $circlesize / -3;
            animation: MoveCircle1 25s ease-in-out infinite;
        }

        .circle2 {
            top: 0;
            right: 0;
            animation: MoveCircle2 25s ease-in-out infinite;
            transform: rotate(180deg);
        }

        .circle3 {
            top: 50%;
            left: 0;
            animation: MoveCircle3 25s ease-in-out infinite;
            transform: rotate(180deg);
        }

        .circle4 {
            bottom: $circlesize / -2;
            right: $circlesize / -3;
            animation: MoveCircle4 25s ease-in-out infinite;
        }
    }
}

@keyframes MoveCircle1 {
    0%,
    100% {
        top: $circlesize / -3;
        left: $circlesize / -3;
    }
    25% {
        top: 0;
        left: $circlesize / -3;
    }
    50% {
        top: 0;
        left: 0;
    }
    75% {
        top: $circlesize / -3;
        left: 0;
    }
}

@keyframes MoveCircle2 {
    0%,
    100% {
        top: 0;
        right: 0;
    }
    25% {
        top: 0;
        right: $circlesize / -3;
    }
    50% {
        top: $circlesize / -3;
        right: $circlesize / -3;
    }
    75% {
        top: $circlesize / -3;
        right: 0;
    }
}

@keyframes MoveCircle3 {
    0%,
    100% {
        top: 50%;
        left: 0;
    }
    25% {
        top: 40%;
        left: 12%;
    }
    50% {
        top: 45%;
        left: 15%;
    }
    75% {
        top: 47.5%;
        left: 3%;
    }
}

@keyframes MoveCircle4 {
    0%,
    100% {
        bottom: $circlesize / -2;
        right: $circlesize / -3;
    }
    25% {
        bottom: -$circlesize;
        right: $circlesize / -3;
    }
    50% {
        bottom: -$circlesize;
        right: $circlesize / -2;
    }
    75% {
        bottom: $circlesize / -2;
        right: $circlesize / -2;
    }
}
